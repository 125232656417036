<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        추천 답변
        <v-spacer />
        <v-btn outlined color="error" @click="deleteCoreReply">
          삭제하기
        </v-btn>
      </v-card-title>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>작성 멘토</v-list-item-title>
            <MentorInput v-model="mentor" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>라벨</v-list-item-title>
            <LabelInput v-model="stamp" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>투명 라벨</v-list-item-title>
            <div>
              <v-chip
                v-for="(tag, i) in invisibleStamp"
                :key="i"
                label
                color="#888"
                close
                text-color="#FFF"
                :class="`mr-1 mb-1`"
                @click:close="invisibleStamp.splice(i, 1)"
              >
                {{ tag.name }}
              </v-chip>
            </div>
            <v-btn @click="$refs.updateInvisibleStampDialog.show()">
              투명라벨 추가하기
            </v-btn>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>제목</v-list-item-title>
            <v-text-field v-model="title" outlined dense hide-details />
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>내용</v-list-item-title>
            <MarkdownEditor v-model="main" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
    <v-card-actions>
      <v-spacer />
      <v-btn large color="#7f00ff" dark @click="updateRecommendAnswer">
        수정하기
      </v-btn>
    </v-card-actions>
    <UpdateInvisibleStampDialog ref="updateInvisibleStampDialog" @refresh="pushInvisibleStamp" />
  </v-container>
</template>

<script>
import { defineComponent, onBeforeMount, reactive, toRefs } from '@vue/composition-api'
import QuestionTag from '@/components/QuestionTag'
import MarkdownEditor from '@/components/MarkdownEditor'
import UpdateInvisibleStampDialog from '@/components/dialog/UpdateInvisibleStampDialog.vue'
import MentorInput from '@/components/input/Mentor.vue'
import LabelInput from '@/components/input/Label.vue'

export default defineComponent({
  components: {
    QuestionTag,
    MarkdownEditor,
    UpdateInvisibleStampDialog,
    MentorInput,
    LabelInput,
  },

  setup(props, { root }) {
    const state = reactive({
      coreReply: {},
      main: '',
      title: '',
      mentor: null,
      stamp: {
        id: null,
        firstDepth: null,
        secondDepth: null,
        thirdDepth: null,
      },
      invisibleStamp: [],
      stampList: [],
    })

    const getOneCoreReply = async (id) => {
      try {
        state.coreReply = await root.$store.dispatch('coreReply/getOneCoreReply', {
          id: Number(id),
        })

        state.mentor = state.coreReply.author
        state.title = state.coreReply.title
        state.main = state.coreReply.main
        state.stamp = state.coreReply.stamp[0]
        state.invisibleStamp = state.coreReply.invisibleStamp
      } catch (err) {
        console.log(err)
      }
    }

    const deleteCoreReply = async () => {
      try {
        if (!confirm('추천답변을 삭제합니다. 추천답변 삭제 후 되돌릴 수 없습니다.')) return

        await root.$store.dispatch('coreReply/deleteCoreReply', {
          id: Number(root.$route.params.uid),
        })
        alert('추천답변이 정상적으로 삭제되었습니다.')

        await root.$router.push('/reply')
      } catch (err) {
        console.log(err)
        alert(err)
      }
    }

    const pushInvisibleStamp = () => {
      root.$store.state.column.columnInvisibleStamp.forEach((x) => {
        if (!state.invisibleStamp.find((y) => y.name === x))
          state.invisibleStamp.push({
            name: x,
          })
      })
    }

    const updateRecommendAnswer = async () => {
      try {
        if (!confirm('추천답변을 수정합니다.')) return

        await root.$store.dispatch('coreReply/updateCoreReply', {
          id: Number(root.$route.params.uid),
          mentorId: Number(state.mentor.id),
          main: state.main,
          title: state.title,
        })

        await root.$store.dispatch('coreReply/changeCoreReplyStamp', {
          id: Number(root.$route.params.uid),
          stampList: [Number(state.stamp.id)],
        })

        await root.$store.dispatch('coreReply/changeCoreReplyInvisibleStamp', {
          id: Number(root.$route.params.uid),
          stampList: state.invisibleStamp.map((e) => e.name),
        })

        alert('추천답변이 정상적으로 수정되었습니다.')
      } catch (err) {
        console.log(err)
        alert(err)
      }
    }

    onBeforeMount(async () => {
      await getOneCoreReply(root.$route.params.uid)
    })

    return {
      ...toRefs(state),
      deleteCoreReply,
      pushInvisibleStamp,
      updateRecommendAnswer,
    }
  },
})
</script>
