<template>
  <div class="text-center">
    <v-dialog v-model="isShow" width="500">
      <v-card>
        <v-card-title>투명라벨</v-card-title>
        <v-card-text>
          <v-list-item>
            <v-list-item-content>
              <v-combobox
                v-model="selectedInvisibleTags"
                :filter="filter"
                :hide-no-data="!search"
                :items="tagItems"
                :search-input.sync="search"
                outlined
                hide-selected
                hide-details
                multiple
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <span class="subheading mr-3">Create</span>
                    <v-chip color="#7f00ff" label small dark>
                      {{ search }}
                    </v-chip>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ attrs, item, parent, selected }">
                  <v-chip v-if="item === Object(item)" v-bind="attrs" :color="item.color" :input-value="selected" label small dark>
                    <span class="pr-2">
                      {{ item.text }}
                    </span>
                    <v-icon small @click="parent.selectItem(item)">
                      mdi-close
                    </v-icon>
                  </v-chip>
                </template>
                <template v-slot:item="{ index, item }">
                  <v-text-field
                    v-if="editing === item"
                    v-model="editing.text"
                    autofocus
                    flat
                    background-color="transparent"
                    hide-details
                    solo
                    @click.stop
                    @keyup.enter="edit(index, item)"
                  />
                  <v-chip v-else :color="item.color" dark label small>
                    {{ item.text }}
                  </v-chip>
                  <v-spacer />
                  <v-list-item-action @click.stop>
                    <v-btn icon @click.stop.prevent="edit(index, item)">
                      <v-icon>
                        {{ editing !== item ? 'mdi-pencil' : 'mdi-check' }}
                      </v-icon>
                    </v-btn>
                  </v-list-item-action>
                </template>
              </v-combobox>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="error" @click="isShow = false">취소</v-btn>
          <v-btn color="primary" @click="updateInvisibleStamp">확인</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { defineComponent, reactive, watch, toRefs } from '@vue/composition-api'

export default defineComponent({
  setup(props, { root, emit }) {
    const state = reactive({
      isShow: false,
      request: {
        id: null,
        selectedTags: [],
      },
      tagItems: [],
      selectedInvisibleTags: [],
      activator: null,
      attach: null,
      editing: null,
      editingIndex: -1,
      menu: false,
      search: null,
    })

    const show = () => {
      state.isShow = true
      root.$store.dispatch('stamp/getListInvisibleStamp').then((res) => {
        state.tagItems = res
          .map((e) => ({
            id: e.id,
            text: e.name,
            color: null,
          }))
          .sort((x, y) => x.text.localeCompare(y.text))
      })
    }

    const updateInvisibleStamp = () => {
      state.request.invisibleStampList = state.selectedInvisibleTags.map((tag) => {
        if (typeof tag == 'object') {
          return tag['text']
        }
        return tag
      })

      root.$store.state.column.columnInvisibleStamp = state.request.invisibleStampList
      state.isShow = false
      state.selectedInvisibleTags = []
      emit('refresh')
    }

    const edit = async (index, item) => {
      if (!state.editing) {
        state.editing = item
        state.editingIndex = index
      } else {
        await root.$store.dispatch('stamp/updateInvisibleStamp', {
          id: Number(state.editing.id),
          name: state.editing.text,
        })

        state.editing = null
        state.editingIndex = -1
      }
    }

    const filter = (item, queryText, itemText) => {
      if (item.header) return false

      const hasValue = (val) => (val != null ? val : '')

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return (
        text
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      )
    }

    watch(
      () => state.selectedInvisibleTags,
      (val, prev) => {
        console.log(state.tagItems)
        if (val.length === prev.length) return

        state.selectedInvisibleTags = val.map((v) => {
          if (typeof v === 'string') {
            v = {
              text: v,
              color: '#7f00ff',
            }

            state.tagItems.push(v)
          }

          return v
        })
      },
    )

    return {
      ...toRefs(state),
      updateInvisibleStamp,
      show,
      edit,
      filter,
    }
  },
})
</script>
